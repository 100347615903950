
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* adding fonts below */
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratLight.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratRegular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratMedium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratBold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

html,body,#root{
  height: 100%;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}
/* Styles for nav section */
.logo{
  max-width: 300px;
}
.nav-z{
  z-index: 110;
}
.nav-bar-padding{
  @apply pt-32 xl:pt-40 2xl:pt-52;
}
.active_link:after{
  content: "";
  height: 40px;
  width: 3px;
  position: absolute;
  left: -8px;
  bottom: 0;
  transform: translateY(50%);
  background: #043c7b;

}

/* menu btn */
.menu-btn div:nth-child(1){
  top: 0%;
  transform: translateY(0%) rotate(0);
}
.menu-btn div:nth-child(2){
  top: 50%;
  transform: translateY(-50%) rotate(0);
}
.menu-btn div:nth-child(3){
  top: 100%;
  transform: translateY(-100%) rotate(0);
}
.menu-btn-active div:nth-child(1){
  top: 50%;
  transform: translateY(-50%) rotate(225deg);
}
.menu-btn-active div:nth-child(2){
  top: 50%;
  transform: translateY(-50%) rotate(225deg);
}
.menu-btn-active div:nth-child(3){
  top: 50%;
  transform: translateY(-50%) rotate(135deg);
}

.sidebar{
  /* width: 500px; */
  width: 100%;
  z-index: 100;
}


.spanStyle span{
  @apply text-gray-text font-light text-sm md:text-lg py-1 capitalize;
}

.relative-z{
  z-index: -1;
}

.table-head-row{
  @apply py-3 px-16  tracking-wide text-center text-base;
}
.table-data-row{
  @apply py-6 px-16 tracking-wide  text-lg text-blue-primary font-light ;
}
.table-data-row td{
  @apply py-2 ;
}
.hidden-td{
  @apply px-3 py-1;
}

.hidden-td span{
  @apply pl-2 pr-3 my-1 text-blue-primary border-r-2 border-gray-border text-sm lg:text-lg  font-medium items-center inline-flex ; 
}
.video-block{
  width: 100% !important;
}

.search-form{
  position: relative;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-50%);
  /* width: 50%; */
}

.swiper-button-prev, .swiper-button-next  {
  
  color: white !important;
  position: absolute;
  left: 5px;
  /* z-index: 999; */
}

/* .swiper-button-next {
  position: absolute;
  right: 5px;
  color: white !important;
} */
.swiper-slide{
  width: 10rem !important;
}
.swiper-container{
  margin: 0 2px !important;
  width: 100% !important;
}

.webinar-image{
  min-height: 35rem;
}
.webinar-detail-page-image{
  min-height: 600px;
}

.doc-image{
  height:20rem;
}
@media screen and (max-width:600px){
  .webinar-image{
    min-height: 25rem;
  }
  .webinar-detail-page-image{
    min-height: 300px;
  }
  .doc-image{
    height: 15rem;
  }
}

@media screen and (max-width:340px){
  .logo{
      max-width: 210px;
  }
  
}

/* .swiper-wrapper{
  width: 100% !important;
} */

.swiper-slide{
  width: 17rem !important;
}